<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile :loading="categoriesLoading" class="rounded-b-xl">
			<v-row class="pa-11 pb-0 justify-start align-center">
				<h3>Categories</h3>
				<v-dialog v-model="dialogNewCategory" max-width="550px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" color="pink" fab small class="mx-3">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<NewCategory 
						@NewCategoryAdded="updateCategories"
						@CloseDialogNewCat="dialogNewCategory = false"
					/>
				</v-dialog>
			</v-row>
			
			<v-simple-table class="pa-4">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Name</th>
							<th class="text-left">Servers</th>
							<th class="text-left">Subcategories</th>
							<th class="text-left"></th>
						</tr>
					</thead>
					
					<tbody>
						<EditCategories
							v-for="(category) in categories" 
							:key="category.id"
							:category="category" 
							@CategoryDeleted="fetchCategories"
						/>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>
	</v-col>
</template>

<script>
import NewCategory from './NewCategory.vue';
import EditCategories from './EditCategories.vue';

export default {
	components: {
		NewCategory,
		EditCategories
	},

	data() {
		return {
			dialogNewCategory: false,
			categoriesLoading: true,
			categories: [],
		}
	},

	async mounted() {
		await this.fetchCategories();
	},

	methods: {
		async updateCategories() {
			this.dialogNewCategory = false;
			await this.fetchCategories();
		},

		async fetchCategories() {
			try {
				const request = await fetch(`${this.$store.state.base_url}/categories`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {
					if (res.categories) {
						this.categories = res.categories;
					}
				}
				
				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.categoriesLoading = false;
			}
		},
	},
}
</script>